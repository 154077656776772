import React from "react"
import styled from "styled-components"

const TextDiv = styled.div`
margin:0 20px;
word-wrap: break-word;
text-align:${props => (props.centerElements) ? ("center"):("left")};

& li{
    list-style-type:${props => (props.centerElements) ? ("none"):("disc")};
}
`

export default function TextElement(props){
    return(
        <div id={props.id} className="row">
            <TextDiv centerElements={props.centered} className="l4-9 m2-11 s1-12">
                <p><b>{props.data.title}</b></p>
                {
                    props.data.TextSections.map((item, i) => (
                        <div key={i}>
                            <p><b>{item.title}</b></p>
                            <div dangerouslySetInnerHTML={{__html: item.text}}/>
                        </div>
                    ))
                }
            </TextDiv>
        </div>
    )
}