import React from "react"
import styled from "styled-components"

const Container = styled.div`
text-align:center;
color:${props => props.color};

font-size:4vw;

@media(min-width:601px){
    font-size:1.2em;
}
`

export default function PageHeader(props){

    let color
    (props.color==null) ? (color="black") : (color=props.color)

    return(
        <Container color={color}>
            <h1>{props.children}</h1>
        </Container>
    )
}