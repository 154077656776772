import React from "react"
import styled from "styled-components"
import Top from "../../data/icons/bluelineTop2.svg"

const Container = styled.div`
& svg{
    width:100%;
    height:auto;
    display: block;
    z-index:-1;
}
`

export default function ContactTopBanner(){
    return(
        <Container>
            <Top/>
        </Container>
    )
}