import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

import BottomBanner from "../components/BottomBanner"
import ContactTopBanner from "../components/ContactTopBanner"
import TextElement from "../components/TextElement"
import PageHeader from "../components/PageHeader"

export default function Privacy({data}){
    const privacy = data.allPrivacyJson.nodes[0]

    return(
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Datenschutz</title>
                <html lang="de"/>
            </Helmet>
            <Layout>
                <ContactTopBanner/>
                <PageHeader color="#333">Datenschutzerklärung</PageHeader>
                <TextElement data={privacy} />
                <BottomBanner/>
            </Layout>
        </div>
    )
}

export const query = graphql`
{
  allPrivacyJson{
      nodes{
          title
          TextSections{
              title
              text
          }
      }
  }
}`